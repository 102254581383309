import { AxiosInstance } from 'axios';
import { HomeTranslationsType } from '../types/HomeTranslationsType';
import { i18nAppend } from '../helpers/i18nAppend';

let cachedHomeData: Promise<HomeTranslationsType[]> | null = null;

const fetchHomeData = async (axios: AxiosInstance) => {
  if (!cachedHomeData) {
    cachedHomeData = axios.get('/hackathon-cms/v1/marksite/home').then(response => response.data);
  }
  // console.log('cachedHomeData', cachedHomeData);
  return cachedHomeData;
};

export const getAboutCathayHackathonTranslations = async (axios: AxiosInstance) => {
  const aboutCathayHackathonTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredAboutCathayHackathonTranslations = aboutCathayHackathonTranslations.filter((obj) => {
    return obj.id != null;
  });

  const aboutCathayHackathonResponseStructure = {
    about_section_name: '',
    about_title: '',
    about_description: '',
    about_recap_title: '',
    about_recap_card: [{
      number: '',
      description: ''
    }],
    about_video_link: '',
    about_video_thumbnail:  '',
    about_recap_button_text: '',
    about_recap_button_link: '',
  };

  i18nAppend(filteredAboutCathayHackathonTranslations, 'about_cathay_hackathon', aboutCathayHackathonResponseStructure);

  return filteredAboutCathayHackathonTranslations[0].about_recap_card.map((value, index) => {
    return index;
  });
};

export const getHeroBannerTranslations = async (axios: AxiosInstance) => {
  const HeroBannerCard: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredHeroBannerCard = HeroBannerCard.filter((obj) => {
    return obj.id != null;
  });

  const HeroBannerResponseStructure = {
    banner_description: '',
    banner_image: '',
    banner_description_mobile: '',
    banner_image_alt_text: '',
    banner_subtitle: '',
    banner_title: '',
  };

  i18nAppend(filteredHeroBannerCard, 'herobanner', HeroBannerResponseStructure);

  return filteredHeroBannerCard;
};

export const getDetailTranslations = async (axios: AxiosInstance) => {
  const detailTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredDetailTranslations = detailTranslations.filter((obj) => {
    return obj.id != null;
  });

  const detailResponseStructure = {
    event_card: [{
      title:'',
      icon_link:'',
      description:'',
      icon_alt_text:'',
    }
    ],
    event_date_range: '',
    event_description: '',
    event_image: '',
    event_image_alt_text: '',
    event_title: '',
    event_venue: '',
    event_closed_text: '',
  };

  i18nAppend(filteredDetailTranslations, 'detail', detailResponseStructure);

  return filteredDetailTranslations[0].event_card.map((value, index) => {
    return index;
  });
};

export const getFaqTranslations = async (axios: AxiosInstance) => {
  const faqTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredHomeTranslations = faqTranslations.filter((obj) => {
    return obj.id != null;
  });
  const faqResponseStructure  = {
    faq_card: [{
      questions:'',
      answer:''
    }],
    faq_section_name: '',
    faq_title: ''
  };

  i18nAppend(filteredHomeTranslations, 'faq', faqResponseStructure);
  return filteredHomeTranslations[0].faq_card.map((value, index) => {
    return index;
  });
};

export const getHighlightTranslations = async (axios: AxiosInstance) => {
  const HighlightsCard: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredHighlightsCard = HighlightsCard.filter((obj) => {
    return obj.id != null;
  });

  const highlightResponseStructure = {
    highlight_card: [{
      title: '',
      image_link: '',
      description: '',
    }],
    highlight_description: '',
    highlight_section_name: '',
    highlight_title: '',
  };

  i18nAppend(filteredHighlightsCard, 'highlight', highlightResponseStructure);

  return filteredHighlightsCard[0].highlight_card.map((value, index) => {
    return index;
  }
  );
};

export const getRecapTranslations = async (axios: AxiosInstance) =>{
  const RecapTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredHomeTranslations = RecapTranslations.filter((obj)=>{
    return obj.id != null;
  });
  const recapResponseStructure = {
    recap_header_title:'',
    recap_title:'',
    recap_description:'',
    recap_read_more_text:'',
    recap_read_more_link:'',
    recap_winner_cards:[{
      team_name:'',
      group_photo_link:'',
      winning_place_text:''
    }],
    about_video_link: '',
    about_video_thumbnail: ''
  };
  i18nAppend(filteredHomeTranslations, 'recap', recapResponseStructure);
  return filteredHomeTranslations[0].recap_winner_cards.map((value,index)=>{
    return index;
  });
};

export const getJourneyTranslations = async (axios: AxiosInstance) => {
  const journeyTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredHomeTranslations = journeyTranslations.filter((obj) => {
    return obj.id != null;
  });

  const journeyResponseStructure = {
    timeline_card: [{
      title: '',
      venue: '',
      image_link: '',
      time_range: '',
      description: ''
    }],
    timeline_description: '',
    timeline_section_name: '',
    timeline_title: ''
  };

  i18nAppend(filteredHomeTranslations, 'journey', journeyResponseStructure);

  return filteredHomeTranslations[0].timeline_card.map((value, index) => {
    return index;
  });
};

export const getPrizesAndAwardsTranslations = async (axios: AxiosInstance) => {
  const prizesAndAwardsTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredPrizesAndAwardsTranslations = prizesAndAwardsTranslations.filter((obj) => {
    return obj.id != null;
  });

  const prizesAndAwardsResponseStructure = {
    prizes_section_name: '',
    top_winners_title: '',
    top_winners_card: [
      {
        title: '',
        icon_link: '',
        description: '',
        icon_alt_text: '',
      },
    ],
    other_prizes_title: '',
    other_prizes_card: [
      {
        title: '',
        icon_link: '',
        description: '',
        icon_alt_text: '',
      },
    ],
  };

  i18nAppend(filteredPrizesAndAwardsTranslations, 'prizes_and_awards', prizesAndAwardsResponseStructure);

  return {
    top_winners_card_idx: filteredPrizesAndAwardsTranslations[0].top_winners_card.map((val, idx) => idx),
    other_prizes_card_idx: filteredPrizesAndAwardsTranslations[0].other_prizes_card.map((val, idx) => idx),
  };
};

export const getSponsorTranslations = async (axios: AxiosInstance) => {
  const sponsorTranslations: HomeTranslationsType[] = await fetchHomeData(axios);
  const filteredHomeTranslations = sponsorTranslations.filter((obj) => {
    return obj.id != null;
  });

  const sponsorResponseStructure = {
    sponsor_title: '',
    sponsor_type: [{
      title: '',
      value: '',
    }],
    sponsor_logo: [{
      name: '',
      logo: '',
      type: '',
    }],
  };

  i18nAppend(filteredHomeTranslations, 'sponsor', sponsorResponseStructure);
  return filteredHomeTranslations[0].sponsor_logo.map((value, index) => {
    return index;
  });
};