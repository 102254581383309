// import React, { useState, useEffect } from 'react';
import { Container, Nav } from 'react-bootstrap';
import parse from 'html-react-parser';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { axiosNoAuth } from '../../../services/axios';
import { useTranslation } from 'react-i18next';
import { getHeroBannerTranslations } from '../../../services/HomePageServices';
import downArrow from '../../../assets/images/icon/down-arrow.svg';
import { NavHashLink as Link } from 'react-router-hash-link';
// Framer motion animation for entering animation
const initial = {
  y: '1rem',
  opacity: 0,
};

const animate = {
  y: 0,
  opacity: 1,
};

const transition = {
  duration: 1,
  ease: 'easeOut',
  delay: 0.5,
};

const scrollWithOffset = (el: HTMLElement) => {
  // Reference from ResponsiveNavbar.tsx
  const responsiveNavbarElement = document.getElementById('responsive-navbar');
  if (!responsiveNavbarElement) return console.log('No responsive navbar element found.');

  const navBarHeight = responsiveNavbarElement.getBoundingClientRect().height;
  const navBarClassList = responsiveNavbarElement.classList;

  let yOffset = 0;
  if (navBarClassList.contains('sticky') && navBarClassList.contains('sticky-transition') && navBarClassList.contains('sticky-show')){
    // Scrolling up the page, navbar shows
    yOffset = 0;
  } else if (navBarClassList.contains('sticky') && navBarClassList.contains('sticky-transition')) {
    // Scrolling down the page, navbar hidden
    yOffset = 0;
  } else if (navBarClassList.contains('sticky-show')){
    // At top of the page
    yOffset = -(navBarHeight);
  }

  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({
    top: yCoordinate + yOffset,
    behavior: 'smooth'
  });
};

function ImageBanner() {
  const { t } = useTranslation('herobanner');
  const {data: bannerTrans, isLoading} = useQuery({
    queryKey: ['herobanner'],
    queryFn: () => getHeroBannerTranslations(axiosNoAuth),
    staleTime: Infinity
  });
  if (!bannerTrans || isLoading) return null;
  return (
    <section id="about-session" className="hero-banner">
      <img
        className="hero-banner__img"
        src={t('banner_image')}
        width="x"
        height="y"
        tabIndex={-1}
        alt={t('banner_image_alt_text')}
      />
      <img
        className="hero-banner__img_responsive"
        src={t('banner_image')}
        height="y"
        tabIndex={-1}
        alt={t('banner_image_alt_text')}
      />
      <div className="hero-banner__text-wrapper">
        <Container className="container">
          <motion.span
            initial={initial}
            animate={animate}
            transition={transition}
            className="hero-banner__headline"
          >
          </motion.span>
          <motion.h1
            initial={initial}
            animate={animate}
            transition={transition}
            className="hero-banner__title"
          >
            {t('banner_title')}
          </motion.h1>
          <motion.span
            initial={initial}
            animate={animate}
            transition={transition}
            className="hero-banner__text"
          >
            {parse(t('banner_description'))}
          </motion.span>
          <motion.span
            initial={initial}
            animate={animate}
            transition={transition}
            className="hero-banner__text_responsive"
          >
            {parse(t('banner_description_mobile'))}
          </motion.span>
        </Container>
      </div>
      <motion.div
        className="hero-banner__down-arrow"
        initial={initial}
        animate={animate}
        transition={transition}
      >
        <Nav.Link
          as={Link}
          smooth to="#hackathon-Recap"
          scroll={scrollWithOffset}
        >
          <img
            src={downArrow}
            alt='down arrow'
            className="icon"
          />
        </Nav.Link>
      </motion.div>
    </section>
  );
}

export default ImageBanner;