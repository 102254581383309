import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { initialGoUp, whileInView, transition } from '../../../helpers/animations';
import LazyVideo from '../../common/LazyVideo';
import { getAboutCathayHackathonTranslations } from '../../../services/HomePageServices';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { axiosNoAuth } from '../../../services/axios';
import { FC, useState } from 'react';
import parse from 'html-react-parser';
import { Nav } from 'react-bootstrap';
import playIcon from '../../../assets/images/icon/icon-play.svg';

interface StatCardProps{
  statCardIndex: number;
}

const StatCard: FC<StatCardProps> = ({ statCardIndex }) => {
  const {t} = useTranslation('about_cathay_hackathon');
  return(
    <div
      className='about-hackathon-section__stat_card'
    >
      <div className='number'>{t(`about_recap_card_number_${statCardIndex}`)}</div>
      <div className='description'>{t(`about_recap_card_description_${statCardIndex}`)}</div>
    </div>
  );
};

function AboutCathayHackathon() {
  const { t, i18n } = useTranslation('about_cathay_hackathon');
  const {data: recapStatsCardsIdx, isLoading } = useQuery({
    queryKey: ['about_cathay_hackathon'],
    queryFn: () => getAboutCathayHackathonTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const [showVideoControls, setShowVideoControls] = useState(false);
  const [hideVideoIcon, setHideVideoIcon] = useState(false);

  const playVideo = () => {
    const videoEl: HTMLVideoElement | null = document.getElementById('about-hackathon-video') as HTMLVideoElement;
    if (videoEl) {
      setShowVideoControls(true);
      setHideVideoIcon(true);
      videoEl.play();
    }
  };

  if (!recapStatsCardsIdx || isLoading) return null;

  return (
    <section id="about-hackathon" className="about-hackathon-section">
      <Container className="about-hackathon-section__container">
        <motion.div
          initial={initialGoUp}
          whileInView={whileInView}
          transition={transition}
          className='about-and-video-container'
        >
          <div
            className='about_container'
          >
            <div className='name'>{t('about_section_name')}</div>
            <div className='title'>{t('about_title')}</div>
            <div className='description'>{parse(t('about_description'))}</div>
          </div>
          <div
            className='video_container'
          >
            <LazyVideo
              src={t('about_video_link')}
              preload="none"
              width="100%"
              poster = {t('about_video_thumbnail')}
              controls={showVideoControls}
              videoId='about-hackathon-video'
            />
            <img
              src={playIcon}
              alt='Play Icon'
              onClick={() => playVideo()}
              hidden={hideVideoIcon}
              className='overlay_icon'
            />
          </div>
        </motion.div>
        <motion.div
          initial={initialGoUp}
          whileInView={whileInView}
          transition={transition}
          className='stats_container'
        >
          <div className='title'>{t('about_recap_title')}</div>
          <div className='stats_cards_container'>
            {
              recapStatsCardsIdx?.map((idx) => (
                <StatCard key={`stat_card_${idx}`} statCardIndex={idx}/>
              ))
            }
          </div>

        </motion.div>
        <motion.div
          initial={initialGoUp}
          whileInView={whileInView}
          transition={transition}
          className='learn_more_container'
        >
          {/* To be integrated with CMS */}
          <Nav.Link
            className="learn_more_button"
            href={'/' + i18n.language + t('about_recap_button_link') + '?cxsource=HACKATHON_LEARN_MORE'}
          >
            {t('about_recap_button_text')}
          </Nav.Link>
        </motion.div>
      </Container>
    </section>
  );
}

export default AboutCathayHackathon;
