import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getRecapTranslations } from '../../../services/HomePageServices';
import { axiosNoAuth } from '../../../services/axios';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import parse from 'html-react-parser';
import LazyVideo from '../../common/LazyVideo';
import playIcon from '../../../assets/images/icon/icon-play.svg';
import arrowIcon from '../../../assets/images/icon/carouselbutton_green.svg';

interface WinnerCardProps {
  index: number;
}

function WinnerCard({index}: WinnerCardProps) {
  const {t} = useTranslation('recap');
  return(
    <div className="winner-card">
      <img className="winner-card__image"
        src={t(`recap_winner_cards_group_photo_link_${index}`)}
        alt={t(`recap_winner_cards_team_name_${index}`)}/>
      <div className="winner-card__textbox">
        <div className="winner-card__section_title">{t(`recap_winner_cards_winning_place_text_${index}`)}</div>
        <div className="winner-card__title">{t(`recap_winner_cards_team_name_${index}`)}</div>
      </div>
    </div>
  );
}

function HackathonRecap() {
  const { t } = useTranslation('recap');
  const { data: RecapTranslations, isLoading } = useQuery({
    queryKey: ['recap'],
    queryFn: () => getRecapTranslations(axiosNoAuth),
    staleTime: Infinity
  });

  const [showVideoControls, setShowVideoControls] = useState(false);
  const [hideVideoIcon, setHideVideoIcon] = useState(false);

  const playVideo = () => {
    const videoEl: HTMLVideoElement | null = document.getElementById('hackathon-video') as HTMLVideoElement;
    if (videoEl) {
      setShowVideoControls(true);
      setHideVideoIcon(true);
      videoEl.play();
    }
  };

  if (!RecapTranslations || isLoading) return null;

  return(
    <section id='hackathon-Recap' className='hackathon-recap'>
      <Container className='hackathon-recap__container'>
        <div className="recap-container">
          <div className="recap_container">
            <div className='name'>{t('recap_header_title')}</div>
            <div className="title">{t('recap_title')}</div>
            <div className="description">{parse(t('recap_description'))}</div>
            <a
              className='link'
              href={t('recap_read_more_link')}
            >
              {t('recap_read_more_text')}
              <img
                src={arrowIcon}
                className='arrow'
                alt='arrow icon'
              />
            </a>
          </div>
          <div className='video_container'>
            <LazyVideo
              src={t('about_video_link')}
              preload="none"
              width="100%"
              poster={t('about_video_thumbnail')}
              controls={showVideoControls}
              videoId='hackathon-video'
            />
            <img
              src={playIcon}
              alt='Play Icon'
              onClick={() => playVideo()}
              hidden={hideVideoIcon}
              className='overlay_icon'
            />
          </div>
        </div>
        <div className="prize_container">
          <div className="prize_cards_container">
            {
              RecapTranslations?.map((index) => {
                return(
                  <WinnerCard key={index} index={index}/>
                );
              })
            }
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HackathonRecap;